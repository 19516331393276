<script setup lang="ts">
import * as Sentry from "@sentry/browser";
import VModalAuth from "@magnit/core/src/containers/VModalAuth/VModalAuth.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import useAuthCookies from "@magnit/layer-api/composables/useAuthCookies";
import { IProfileIncompleteCookieEnum } from "@magnit/layer-api/typings/cookies";
import VOnboarding from "~/containers/VOnboarding/VOnboarding.vue";
import { COOKIE_AUTOTESTS_MARK } from "~/constants/cookie";
import { OPEN_AUTH_MODAL_ON_INIT_QP } from "~/constants/queryParams";

const router = useRouter();
const route = useRoute();
const { isApp } = useQueryState();
const { error: showToastError } = useNotification();
const userStore = useUserStore();
const { send } = useAnalytics();
const {
  authorize,
  authModalProps,
  authModalOpen,
  error,
  errorResponse,
  errorTarget,
  apiCheckProfile,
} = useAuth();
const { profileIncompleteState } = useAuthCookies();

useHead({
  title: "Вход в личный кабинет Мой Магнит",
  meta: [
    {
      name: "description",
      content:
        "Регистрация в личном кабинете Мой Магнит. Вход по номеру телефона. Бонусы и Магнитики. Предложения от партнёров",
    },
    {
      name: "theme-color",
      content: "#ffe2e0",
    },
  ],
});

const isAutotests = useCookie(COOKIE_AUTOTESTS_MARK, {
  readonly: true,
  watch: false,
});
const displayDisclaimer = computed(() => authModalProps.value?.form === "phone");

definePageMeta({
  hideCookiePopup: true,
  name: "login",
});

onBeforeMount(() => {
  userStore.$reset();
});

const handleAuth = async () => {
  try {
    scrollLock.enable();
    const result = await authorize();
    if (result.isRegistered) {
      if (!profileIncompleteState.value) {
        await apiCheckProfile();
      }
      if (profileIncompleteState.value === IProfileIncompleteCookieEnum.valid) {
        const redirectTo = (route.query?.redirect as string) ?? Routes.Main;
        if (!isExternal(redirectTo)) {
          const query: Record<string, string> = { origin: "login" };
          if (redirectTo === Routes.PaymentMethodsPay) {
            query.subscription = "1";
          }
          await router.push({ path: redirectTo, query });
        } else {
          window.location.replace(redirectTo);
        }
      }
    } else {
      await router.push({
        path: Routes.Registration,
        query: route.query,
      });
    }
  } catch (err) {
    console.warn("Ошибка авторизации", { err });
  } finally {
    authModalOpen.value = false;
    scrollLock.disable();
  }
};

onMounted(async () => {
  send("LoginPage:View");

  if (isApp.value || route.query[OPEN_AUTH_MODAL_ON_INIT_QP]) {
    await handleAuth();
  }
});

const onLoginModalOpen = async () => {
  send("LoginModal:Opener:Click");
  await handleAuth();
};
watch(error, (next) => {
  if (!next) return;
  showToastError();
});
watch(errorResponse, (next) => {
  if (!next) return;
  const target = errorTarget.value ?? "unknown";
  Sentry.captureMessage(`Ошибка запроса авторизации -- ${target}`, { extra: { response: next } });
});
</script>

<template>
  <VOnboarding @login="onLoginModalOpen" />
  <VModalAuth
    class="page-login__modal-auth"
    v-bind="authModalProps"
    :captcha-key="authModalProps.captchaKey"
    :disable-captcha="Boolean(isAutotests)"
  >
    <template #disclaimer>
      <LProfileUserDisclaimer
        v-if="displayDisclaimer"
        class="page-login__modal-auth-disclaimer"
        @click:agreement="send('RegistrationPage:Agreement:Click')"
        @click:rules="send('RegistrationPage:Rules:Click')"
        @click:conditions="send('RegistrationPage:Conditions:Click')"
      />
    </template>
  </VModalAuth>
</template>

<style lang="postcss" scoped>
.page-login {
  &__modal-auth-disclaimer {
    margin-top: var(--pl-unit-x4);
    text-align: center;

    :deep(a) {
      color: var(--pl-text-link-default);
      text-decoration: none;
    }
  }
}
</style>
