<script lang="ts" setup>
import VText from "@magnit/core/src/components/VText/VText.vue";
import CloseIcon from "~/assets/svg/icons/close.svg";
import QRIcon from "~/assets/svg/pages/onboarding/qr.svg";

const emit = defineEmits<{
  close: [];
}>();
const onClose = () => emit("close");
</script>

<template>
  <div class="onboarding-qr">
    <div class="onboarding-qr__header">
      <VText font="body-small-accent-low" color="invert-primary">
        Добавьте иконку <br>
        Магнит Онлайн
      </VText>
      <CloseIcon class="onboarding-qr__close" @click="onClose" />
    </div>

    <div class="onboarding-qr__image">
      <QRIcon />
    </div>
  </div>
</template>

<style lang="postcss">
.onboarding-qr {
  width: 180px;
  height: 220px;
  background-color: var(--pl-project-core-primary);
  border-radius: 20px;
  padding: 12px 16px 16px;
  z-index: 10;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__close {
    color: white;
    cursor: pointer;
  }

  &__image {
    width: 148px;
    height: 148px;
    margin-top: 8px;
    border-radius: 16px;
    background-color: white;
  }
}
</style>
