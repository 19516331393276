<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectFade, Pagination } from "swiper/modules";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import type { Swiper as SwiperClass } from "swiper/types";
import VOnboardingQr from "~/containers/VOnboarding/VOnboardingQr.vue";

import "swiper/css";
import "swiper/css/grid";
import "swiper/css/navigation";
import "swiper/css/pagination";

interface ISlide {
  id: number;
  title: string;
  subtitle: string;
  className: string;
  eventTitle: string;
}

const emit = defineEmits<{
  login: [];
}>();

const { open: openPwaIosInstruction } = usePwaIosInstructionModal();
const { send } = useAnalytics();
const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const swiperInstance = ref<SwiperClass>();
const showQr = ref<boolean>(false);
const isIos = ref<boolean>(false);
const isPwa = computed(() => platformStore.platformInfo.pwa);

const slides = computed(() => {
  const items: ISlide[] = [
    {
      id: 1,
      title: "Карта Магнит Плюс в телефоне",
      subtitle: "Активируйте пластиковую карту и показывайте QR-код на кассе",
      className: "slide-card",
      eventTitle: "card",
    },
    {
      id: 2,
      title: "3 любимые категории",
      subtitle: "Выбирайте каждый месяц и получайте от 10% бонусами",
      className: "slide-fav-cat",
      eventTitle: "favoriteCategory",
    },
    {
      id: 3,
      title: "Купоны и выгодные предложения",
      subtitle: "Получайте больше бонусов и скидок с картой из приложения",
      className: "slide-coupons",
      eventTitle: "coupons",
    },
    {
      id: 4,
      title: "Добавьте иконку на рабочий стол",
      subtitle: "Магнит Онлайн не занимает места и работает даже на старых смартфонах",
      className: "slide-add-icon",
      eventTitle: "addIcon",
    },
  ];

  if (!isPwa.value) return items;
  return items.slice(0, -1);
});
const showInstallPwaBtn = computed(() => lastSlideDisplayed.value && !isPwa.value);
const modules = [Pagination, EffectFade];
const onSwiper = (swiper: SwiperClass) => (swiperInstance.value = swiper);
const lastSlideIndex = computed(() =>
  swiperInstance.value?.slides ? swiperInstance.value?.slides.length - 1 : 0,
);
const lastSlideDisplayed = computed(
  () => swiperInstance.value?.activeIndex === lastSlideIndex.value,
);
const onLoginClick = () => {
  if (swiperInstance.value) {
    const title = slides.value[swiperInstance.value?.activeIndex].eventTitle;
    send("Onboarding:Login:Click", { title });
  }
  emit("login");
};
const onNextClick = () => swiperInstance.value?.slideNext();
const handleQrClose = () => (showQr.value = false);
const installEnabled = computed(() => {
  if (isIos.value) return true;
  return !isPwa.value && !pwaStore.state.appInstalled && pwaStore.state.deferredInstallPrompt;
});
const onInstallPwaClick = () => {
  send("Onboarding:Install:Click");
  if (installEnabled.value) {
    if (isIos.value) {
      openPwaIosInstruction();
    } else {
      pwaStore.showPrompt("onboarding");
    }
  }
};

onMounted(() => {
  isIos.value = getMobileOS().toLowerCase() === "ios";
  setTimeout(() => {
    showQr.value = true;
  }, 500);
});

watch(
  () => swiperInstance.value?.activeIndex,
  (newIndex) => {
    if (newIndex !== undefined) {
      const title = slides.value[newIndex].eventTitle;
      send("Onboarding:View", { title });
    }
  },
);
</script>

<template>
  <div class="onboarding">
    <Swiper
      effect="fade"
      :speed="300"
      :fade-effect="{ crossFade: true }"
      :pagination="true"
      :modules="modules"
      class="onboarding__swiper"
      :navigation="{ nextEl: '.onboarding__button.next' }"
      @swiper="onSwiper"
    >
      <SwiperSlide v-for="slide in slides" :key="slide.id" class="onboarding__slide">
        <VText font="headline-large" class="onboarding__title" :class="slide.className" tag="div">
          {{ slide.title }}
        </VText>
        <VText font="body-large-regular" class="onboarding__subtitle" tag="div">
          {{ slide.subtitle }}
        </VText>
        <div class="onboarding__banner" :class="`onboarding__banner--${slide.className}`" />
      </SwiperSlide>
    </Swiper>
    <div class="onboarding__buttons">
      <VButton
        v-if="!lastSlideDisplayed"
        theme="invert"
        class="onboarding__button next"
        @click="onNextClick"
      >
        Далее
      </VButton>
      <VButton
        v-if="showInstallPwaBtn"
        theme="invert"
        :disabled="!installEnabled"
        class="onboarding__button add-icon"
        @click="onInstallPwaClick"
      >
        Добавить
      </VButton>
      <VButton theme="primary" class="onboarding__button login" @click="onLoginClick">
        Войти
      </VButton>
    </div>
    <Transition name="slide">
      <VOnboardingQr v-if="showQr" class="onboarding__qr" @close="handleQrClose" />
    </Transition>
  </div>
</template>

<style lang="postcss">
.onboarding {
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(45deg, #ffebde, #ffe0cb, #ffebde);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);

  @media (--pl-viewport-from-m) {
    flex-direction: column;
  }

  @media (--pl-viewport-from-ml) {
    align-items: center;
  }

  &__title {
    &.slide-fav-cat {
      width: 300px;
      margin-left: auto;
      margin-right: auto;

      @media (--pl-viewport-from-m) {
        width: unset;
      }
    }
  }

  &__title,
  &__subtitle {
    padding: 0 20px;
  }

  &__swiper.swiper-horizontal {
    height: 100%;
    padding-top: 60px;
    max-width: 560px;

    @media (--pl-viewport-from-ml) {
      max-height: 700px;
    }

    .swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
      display: flex;
      justify-content: flex-start;
      position: absolute;
      top: 20px;
      bottom: unset;
      left: 20px;
      text-align: left;
      padding: 12px 0;

      @media (--pl-viewport-from-ml) {
        text-align: center;
        display: flex;
        justify-content: center;
        left: 0;
      }
    }

    .swiper-pagination-bullet {
      background-color: #f5a1b1;

      &-active {
        background-color: #b2102f;
      }
    }
  }

  &__slide {
    text-align: center;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    gap: var(--pl-unit-x4);

    @media (--pl-viewport-from-l) {
      &:last-child {
        .onboarding__button.login {
          width: 600px;
        }
      }
    }
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    max-width: 600px;
    display: flex;
    align-items: baseline;
    padding: 0 20px 25px;
    z-index: var(--pl-z-index-level2);

    @media (--pl-viewport-from-ml) {
      position: static;
      left: unset;
      right: unset;
      margin: 20px 0 0;
    }
  }

  &__button {
    min-width: 96px;
    flex-basis: 100%;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &.login {
      background: var(--pl-project-core-primary);
    }

    @media (--pl-viewport-from-l) {
      &.add-icon {
        display: none;
      }
    }
  }

  &__banner {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center 25%;
    flex-grow: 1;

    @each $name, $i in slide-card, slide-fav-cat, slide-coupons, slide-add-icon {
      /* selector example: .onboarding__banner--slide-card */
      &--$(name) {
        background-image: url("/images/onboarding/slide-$(i).webp");
      }
    }
  }

  &__qr {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: none;

    @media (--pl-viewport-from-l) {
      display: block;
    }
  }

  .swiper-fade .swiper-slide-active,
  .swiper-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto;
  }

  .swiper-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity;
  }
}
</style>
